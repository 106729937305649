<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Товары

        GoodsTable(:opts="opts")
</template>

<script>

import GoodsTable from "@/views/App/base/GoodsTable";

export default {
  name: 'Goods',
  components: {GoodsTable},
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false
      }

    }
  },
  methods: {
  }
}
</script>
